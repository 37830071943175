// ページトップ
import commonClass from "./commonClass";

// 共通クラス
export default function instagram(){

  // main() {
    $.ajax({
      type: 'GET',
      url: 'https://graph.facebook.com/v9.0/17841412671374013?fields=name%2Cmedia.limit(8)%7Bcaption%2Clike_count%2Cmedia_url%2Cmedia_type%2Cpermalink%2Ctimestamp%2Cusername%2Cthumbnail_url%7D&access_token=EAA399pjmGP0BAO4lH4Y3K9kbxsEVVVUFAPZC9aqTP47YfLGOeqpALf2avgThkyaMQs5lXV9KCZA4FT6mLkZAgwrUehusIzdle8cWKkxmMQ242MXKFmzsA2gLsR9DkMfW7wqnAPRjRVriXr05lFw6hOPbaRFJXtQMmNYzZAKz1ZCX0oizI2iJv',
      dataType: 'json',
      success: function(json) {
      var html = '';
      var insta = json.media.data;
      for (var i = 0; i < insta.length; i++) {
        let image;
        if(insta[i].thumbnail_url){
          image = insta[i].thumbnail_url;
        } else {
          image = insta[i].media_url;
        }

        html += '<li class="p-top-instagram__item swiper-slide"><a href="' + insta[i].permalink + '" target="_blank"><img src="' + image + '"></a></li>';
      }
      $(".p-top-instagram__list").append(html);
    },
    error: function() {
      $(".p-top-instagram__list").hide();
    }
  });
}