
// 共通クラス
export default function scrollFade(){
  window.addEventListener('DOMContentLoaded', ()=>{
    const element = document.querySelectorAll('.fade-bottom,.fade-top, .fade-in');
    let showTiming = 200; // 要素を表示するタイミング
    const fadein = () => {
      if(!element) return;
      let scrollY = window.pageYOffset;
      let windowH = window.innerHeight;
      for(var i = 0; i < element.length; i++) { 
        var elemClientRect = element[i].getBoundingClientRect(); 
        var elemY = scrollY + elemClientRect.top; 
        if(scrollY > elemY - windowH + showTiming) {
          element[i].classList.add('is-show');
        }
      }
    } 
    window.addEventListener('load', fadein);
    window.addEventListener('scroll', fadein);
  })
}