// ページトップ
import commonClass from "./commonClass";

// 共通クラス
export default function gnav(){
  window.addEventListener('load',function(){
    const btn = document.querySelector('.js-header-btn')
    const nav = document.querySelector('.l-header-nav')
    let navFlg = false;
    btn.addEventListener("click",(e) => {
      btn.classList.toggle('is-active');
      // nav.classList.toggle('is-active');
      nav.classList.toggle('is-active');

      if(!navFlg){
        $('.l-header-nav__item').each(function(i){
            $(this).delay(i*200).animate({
                'opacity' : 1,
            },300);
        });
        navFlg = true;
      }else{
          $('.l-header-nav__item').css({
              'opacity' : 0,
          });
          navFlg = false;
      }
    })
  })
}