import $ from 'jquery';

export default function acordion(){
  window.addEventListener('DOMContentLoaded', ()=>{
  const accordionCont = document.getElementsByClassName("js-accordion-content");
  const accordionBtn = document.getElementsByClassName('js-accordion-trigger')
  for(let i=0; i<accordionBtn.length; i++){
    const btn = accordionBtn[i];
    const elmwrap = btn.closest('.js-accordion-wrap');
    const elmContent = elmwrap.querySelectorAll(".js-accordion-content")[0];
    btn.addEventListener('click',()=> {
      $(elmContent).slideToggle(400,
        function(){
          elmwrap.classList.toggle('is-active')
          elmContent.classList.toggle('is-active');
        }
      );
      $(btn).toggleClass('is-active');
    })
  }

  });
};