// ページトップ
// import commonClass from "./commonClass";

// 共通クラス
export default class commonClass{
// export class CommonClass{
  constructor() {
    this.trigger = '.js-btn';
  }

  openGnav() {
    const btn = document.querySelector('.js-header-btn')
    const nav = document.querySelector('.l-header-nav')
    btn.addEventListener("click",(e) => {
      btn.classList.toggle('is-active');
      nav.classList.toggle('is-active');
    })
  }

//   instagram() {
//     $.ajax({
//       type: 'GET',
//       url: 'https://graph.facebook.com/v9.0/17841412671374013?fields=name%2Cmedia.limit(8)%7Bcaption%2Clike_count%2Cmedia_url%2Cmedia_type%2Cpermalink%2Ctimestamp%2Cusername%2Cthumbnail_url%7D&access_token=EAA399pjmGP0BAO4lH4Y3K9kbxsEVVVUFAPZC9aqTP47YfLGOeqpALf2avgThkyaMQs5lXV9KCZA4FT6mLkZAgwrUehusIzdle8cWKkxmMQ242MXKFmzsA2gLsR9DkMfW7wqnAPRjRVriXr05lFw6hOPbaRFJXtQMmNYzZAKz1ZCX0oizI2iJv',
//       dataType: 'json',
//       success: function(json) {
//       var html = '';
//       var insta = json.media.data;
//       for (var i = 0; i < insta.length; i++) {
//         let image;
//         if(insta[i].thumbnail_url){
//           image = insta[i].thumbnail_url;
//         } else {
//           image = insta[i].media_url;
//         }

//         html += '<li class="p-top-instagram__item swiper-slide"><a href="' + insta[i].permalink + '" target="_blank"><img src="' + image + '"></a></li>';
//       }
//       $(".p-top-instagram__list").append(html);
//     },
//     error: function() {
//       $(".p-top-instagram__list").hide();
//     }
//   });
//   }

  googleMap() {
    window.initMap = () => {
      let map;
      const area = document.getElementById("p-map");
      const center = {
        lat: 32.946376746347134,
        lng: 132.56233145599566
      };
      const styleOptions = [
      {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#6195a0"
              }
          ]
      },
      {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
              {
                  "lightness": "0"
              },
              {
                  "saturation": "0"
              },
              {
                  "color": "#f5f5f2"
              },
              {
                  "gamma": "1"
              }
          ]
      },
      {
          "featureType": "landscape.man_made",
          "elementType": "all",
          "stylers": [
              {
                  "lightness": "-3"
              },
              {
                  "gamma": "1.00"
              }
          ]
      },
      {
          "featureType": "landscape.natural.terrain",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#bae5ce"
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
              {
                  "saturation": -100
              },
              {
                  "lightness": 45
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#fac9a9"
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels.text",
          "stylers": [
              {
                  "color": "#4e4e4e"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#787878"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "transit.station.airport",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "hue": "#0a00ff"
              },
              {
                  "saturation": "-77"
              },
              {
                  "gamma": "0.57"
              },
              {
                  "lightness": "0"
              }
          ]
      },
      {
          "featureType": "transit.station.rail",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#43321e"
              }
          ]
      },
      {
          "featureType": "transit.station.rail",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "hue": "#ff6c00"
              },
              {
                  "lightness": "4"
              },
              {
                  "gamma": "0.75"
              },
              {
                  "saturation": "-68"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
              {
                  "color": "#eaf6f8"
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#c7eced"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "lightness": "-49"
              },
              {
                  "saturation": "-53"
              },
              {
                  "gamma": "0.79"
              }
          ]
      }
      ]

      //マップ作成
      map = new google.maps.Map(area, {
        center,
        zoom: 15,
        // scrollwheel: false,
        mapTypeControl:false,
        streetViewControl: false,
        styles: styleOptions ,
      });

      const markerOption = {
        position: center,
        map: map, // マーカーを立てる地図を指定
        icon: {
          url: 'wp-content/themes/template/assets/img/common/ico-map-pin.svg',
          scaledSize: new google.maps.Size(120, 120), //マーカーのサイズを縮小
          anchor: new google.maps.Point(60, 100) //マーカーの表示位置を25pxだけ上に
        }
      }
      const marker = new google.maps.Marker(markerOption);
    }
  }

  backTop() {
    const backTopBtn = document.querySelector('#backToTop');
    backTopBtn.addEventListener("click",(event) =>{
      event.preventDefault;
      $('body, html').animate({ scrollTop: 0 }, 500);
    });
  }
}

// export class headeraClass{
//   // コンストラクタ
//   constructor() {
//     // super();
//     // this._getSuper();
//     // this._classValiableDefine();
//     // this._init();
//   }

//   // 親classから変数を取得してくる
//   // _getSuper() {
//   // }
//   // class変数定義
//   // _classValiableDefine() {
//   //   this._$anchorScroll = $('.js-anchorScroll');
//   //   this._scrollSpeed = 500;
//   // }

//   // 初期化用関数
//   // _init() {
//   //   this._main();
//   // }

//   // リサイズ用関数
//   // _resize() {
//   // }

//   // メイン処理
//   // _main() {
//   //   var cls = this;
//   //   cls._addMoveToAnchor();
//   // }

//   // トップへのイベントを付与
//   // _addMoveToAnchor() {
//   //   var cls = this;

//   //   cls._$anchorScroll.on('click', function() {
//   //     var anchor = $(this).data('anchor');
//   //     var $anchortgt = $(anchor);
//   //     if ($anchortgt.length) {
//   //       var position = $anchortgt.offset().top;
//   //       $('body, html').stop().animate({ scrollTop: position }, cls._scrollSpeed);
//   //     }
//   //   });
//   // }

// }

// const product = new headerClass('手水と')
// product.addProduct('追加です');
// product.getProduct();
