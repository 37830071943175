// ページトップ
import axios from "axios";


// 共通クラス
export default function top(){

  document.addEventListener('DOMContentLoaded', function() {
    const top = document.getElementsByClassName('p-top-news')[0];
    if(!top) { 
      return false;
    }


      axios.get('/rss.php', {
        // auth: {
        //   username: 'hamami-music',
        //   password: 'lethzm0e63'
        // }
      })
      .then(function (res) {
        const items = xmlTojson(res.data);
  
        for(let index in items) {
  
          // 最大3件
          if (index > 2) {
            break;
          }
  
          // クラス名設定
          let catClass = "p-top-news__item__tag--red";
          if(items[index].category === 'リトミック') {
            catClass = "p-top-news__item__tag--green"
          } else if (items[index].category === 'お知らせ') {
            catClass = "p-top-news__item__tag--red"
          }
  
          // カテゴリ名設定
          let catName = "お知らせ";
          if(items[index].category !== '') {
            catName = items[index].category;
          }
  
          // 日付フォーマット
          const date = new Date(items[index].date);
  
          const hoge = `<li class="p-top-news__item"><a href="${items[index].url}" target="_blank">
              <span class="p-top-news__item__date">${formatDate(date)}</span>
              <span class="p-top-news__item__tag ${catClass}">${catName}</span>
              <p class="p-top-news__item__txt">${items[index].title}</p>
            </a>
          </li>`
          document.querySelector('.p-top-news__list').insertAdjacentHTML('beforebegin', hoge)
  
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    
      function formatDate(date) {
        var y = date.getFullYear();
        var m = ('00' + (date.getMonth()+1)).slice(-2);
        var d = ('00' + date.getDate()).slice(-2);
        return (y + '.' + m + '.' + d);
      }
  
      function xmlTojson(xmlArray){
        let parser = new DOMParser();
        let doc = parser.parseFromString(xmlArray, "application/xml");
        let nl = doc.getElementsByTagName("item");
        let matches = nl.length;
    
        let jsonData = [];
        for (let i = 0; i < matches; i++ ) {
          let e = nl.item(i);
          let youso = [];
          for(let j = 0;j < Math.floor(e.childNodes.length/2);j++){
            let type = e.getElementsByTagName(e.childNodes[1+j*2].nodeName);
            youso.push(type);
          }
          let buf = {title:youso[0].item(0).textContent, url:youso[1].item(0).textContent, date:youso[2].item(0).textContent, category:youso[4].item(0).textContent};
          jsonData.push(buf);
        }
        return jsonData;
      }
  })
}