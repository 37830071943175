// ページトップ
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

// 共通クラス
export default function calendar(){
  document.addEventListener('DOMContentLoaded', function() {
    // let draggableEl = document.getElementById('mydraggable');
    let calendarEl = document.getElementById('calendar');
    if(!calendarEl) {
      return false;
    }
  
    let calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin,googleCalendarPlugin ],
      // initialView: 'dayGridWeek',
      initialView: 'timeGridWeek',
      locale: 'ja',
      googleCalendarApiKey: 'AIzaSyB8hZ73zVzfoASi2_xBEuMcTY3Zlth6nC0',
      allDaySlot:false,
      slotMinTime:'09:00:00',
      slotMaxTime:'21:00:00',
      height: 640,
      firstDay:1,
      events: {
        googleCalendarId: 'hamami.music@gmail.com',
      },
      
    
      headerToolbar: {
        left: 'prev',
        center: 'title',
        right: 'next'
      }
    
      // start: 'prev next',
      // center: 'title',
      // end: ' prev next',
    
      // header: {
      //   start: 'prev next', // will normally be on the left. if RTL, will be on the right
      //   center: 'title',
      //   end: ' prev next' // will normally be on the right. if RTL, will be on the left
      // },
    });
  
    calendar.render();
  
    // new Draggable(draggableEl);
  });
}
