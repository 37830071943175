// モジュールの呼び出し
// import CommonClass from "./module/commonClass.js";
import instagram from "./module/instagram.js";
import googlemap from "./module/googlemap.js";
import backtop from "./module/backtop.js";
import gnav from "./module/gnav.js";
import acordion from "./module/accordion.js";
import smoothScroll from "./module/smoothScroll.js";
import scrollFade from "./module/scrollFade.js";
import calendar from "./module/calendar.js";
import top from "./module/top.js";

// import ScrollOut from 'scroll-out';
// import Splitting from 'splitting';
calendar();
top();
instagram();
googlemap();
backtop();
gnav();
acordion();
smoothScroll();
scrollFade();
// Splitting({ target: '.js-scroll-out' });

// ScrollOut({
//   cssProps: {
//     visibleY: true,
//     viewportY: true
//   }
// });
