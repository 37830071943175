// ページトップ
import commonClass from "./commonClass";

// 共通クラス
export default function backtop(){
  window.addEventListener('load', function(){
    const backTopBtn = document.querySelector('#backToTop');
    backTopBtn.addEventListener("click",(event) =>{
      event.preventDefault;
      $('body, html').animate({ scrollTop: 0 }, 500);
    });
  
  })
}